import * as types from '../mutation-types';
const CALL_ACTION_TIMEOUT_DURATION = 3500;
import { throwErrorMessage } from '../utils/api';
import NotificationsAPI from '../../api/notifications.js';

export const state = {
  callData: null,
  callStatus: null,
  callActionTimeout: null,
  missedCalls: [],
  missedCallsCount: 0,
};

export const getters = {
  getCallData($state) {
    return $state.callData;
  },
  getMissedCalls($state) {
    return $state.missedCalls;
  },
  getMissedCallsCount($state) {
    return $state.missedCallsCount;
  },
  getCallStatus($state) {
    return $state.callStatus;
  },
};

export const actions = {
  fetchMissedCalls: async ({ commit }) => {
    try {
      const {
        data: {
          data: { payload },
        },
      } = await NotificationsAPI.missed();
      commit(types.default.SET_MISSED_CALLS, payload);
      return payload;
    } catch (error) {
      return throwErrorMessage(error);
    }
  },
  fetchMissedUnreadCount: async ({ commit } = {}) => {
    try {
      const { data } = await NotificationsAPI.getMissedUnreadCount();
      commit(types.default.SET_MISSED_CALLS_COUNT, data);
      return data;
    } catch (error) {
      return throwErrorMessage(error);
    }
  },
  readMissedCall: async ({ commit }, { primaryActorType, primaryActorId }) => {
    try {
      if (primaryActorId === 'all') {
        commit(types.default.SET_MISSED_CALLS_COUNT, 0);
        commit(types.default.CLEAR_MISSED_CALLS, 'all');
      } else {
        commit(types.default.CLEAR_MISSED_CALLS, primaryActorId);
      }
      await NotificationsAPI.readMissedCall(primaryActorType, primaryActorId);
      return primaryActorId;
    } catch (error) {
      return throwErrorMessage(error);
    }
  },
  addMissedCall({ commit }, call) {
    commit(types.default.ADD_MISSED_CALL, call);
  },
  setCallStatus({ commit }, status) {
    commit(types.default.SET_CALL_STATUS, status);
  },
  setMissedCalls({ commit }, calls) {
    commit(types.default.SET_MISSED_CALLS, calls);
  },
  setCallData({ commit }, data) {
    commit(types.default.SET_CALL_DATA, data);
  },
  newCall: ({ commit, rootState, dispatch }, data) => {
    // Queue next possible call
    if (
      ['outgoing', 'incoming'].includes(state.callStatus) ||
      !data.meta.assignee
    ) {
      dispatch('queueNextCall', data);
      // Start a new call if assignee is current user
    } else if (rootState.auth?.currentUser.id === data?.meta?.assignee?.id) {
      // Clear possible new/next calls that are resolved
      if (state.callActionTimeout) clearTimeout(state.callActionTimeout);
      const callTimeout = setTimeout(
        () => {
          commit(types.default.SET_CALL_DATA, data);
          commit(types.default.SET_CALL_STATUS, 'incoming');
        },
        // If widget is on hangup stage wait till it colsed before new call
        state.callStatus === 'hangup' ? CALL_ACTION_TIMEOUT_DURATION : 0
      );
      commit(types.default.SET_CALL_ACTION_TIMEOUT, callTimeout);
    }
  },
  nextCall: ({ commit, rootState, dispatch }, data) => {
    // If call state is ongoing conversation.update event should just update existing call data
    if (state.callData && state.callStatus === 'outgoing') {
      dispatch('updateOutgoingCall', data);
      // Initiate next imcoming call
    } else {
      // Clear possible new/next calls that are resolved
      if (state.callActionTimeout) clearTimeout(state.callActionTimeout);

      let queueData = JSON.parse(localStorage.getItem('queuedCalls')) || [];
      // Check storage to make sure next call still actual and assigned to current user
      if (
        queueData.includes(data.id) &&
        rootState.auth?.currentUser.id === data?.meta?.assignee?.id
      ) {
        const callTimeout = setTimeout(
          () => {
            commit(types.default.SET_CALL_DATA, data);
            commit(types.default.SET_CALL_STATUS, 'incoming');
          },
          // If widget is on hangup stage wait till it colsed before next call
          state.callStatus === 'hangup' ? CALL_ACTION_TIMEOUT_DURATION : 0
        );
        commit(types.default.SET_CALL_ACTION_TIMEOUT, callTimeout);
        // Remove current call from storage queue
        queueData = queueData.filter(id => id !== data.id);
        localStorage.setItem('queuedCalls', JSON.stringify(queueData));
      }
    }
  },
  endCall: ({ commit }, data) => {
    // Clear possible new/next calls that are resolved
    if (state.callActionTimeout) clearTimeout(state.callActionTimeout);
    let queueData = JSON.parse(localStorage.getItem('queuedCalls')) || [];
    // Hang up current call
    if (!queueData.includes(data.id) && data.id === state.callData?.id) {
      commit(types.default.SET_CALL_STATUS, 'hangup');
      setTimeout(() => {
        commit(types.default.SET_CALL_DATA, null);
        commit(types.default.SET_CALL_STATUS, null);
      }, CALL_ACTION_TIMEOUT_DURATION);
    }
    // Remove current call from storage queue
    queueData = queueData.filter(id => id !== data.id);
    localStorage.setItem('queuedCalls', JSON.stringify(queueData));
  },
  queueNextCall: (_, data) => {
    // Set next call to storage queue
    const queueData = JSON.parse(localStorage.getItem('queuedCalls')) || [];
    queueData.push(data.id);
    localStorage.setItem('queuedCalls', JSON.stringify(queueData));
  },
  updateOutgoingCall: ({ commit }, data) => {
    // Update outgoing call data with conversation.update event
    if (state.callData && state.callStatus === 'outgoing') {
      commit(types.default.SET_CALL_DATA, data);
    }
  },
};

export const mutations = {
  [types.default.SET_CALL_DATA]($state, data) {
    $state.callData = data;
  },
  [types.default.SET_CALL_STATUS]($state, status) {
    $state.callStatus = status;
  },
  [types.default.SET_MISSED_CALLS]($state, calls) {
    $state.missedCalls = calls;
  },
  [types.default.ADD_MISSED_CALL]($state, call) {
    $state.missedCalls.unshift(call.notification);
    $state.missedCallsCount += 1;
  },
  [types.default.SET_MISSED_CALLS_COUNT]($state, count) {
    $state.missedCallsCount = count;
  },
  [types.default.SET_CALL_ACTION_TIMEOUT]($state, data) {
    $state.callActionTimeout = data;
  },
  [types.default.CLEAR_MISSED_CALLS]($state, id) {
    if (id === 'all') {
      $state.missedCalls = [];
    } else {
      $state.missedCalls = $state.missedCalls.filter(item => {
        return id !== item.primary_actor_id;
      });
    }
    $state.missedCallsCount = $state.missedCalls.length;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
