<template>
  <div class="missed-calls-panel-body">
    <div v-if="isLoading" class="call-based-spinner spinner-cover">
      <spinner-hoory size="normal" color="#623bea" />
    </div>
    <div v-else v-on-clickaway="closeMissedCallsPanel">
      <div class="content-wrap">
        <transition-group
          tag="div"
          name="slide-left-right"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <MissedCallItem
            v-for="missedCall in missedCalls"
            :key="missedCall.id"
            :missed-call-data="missedCall"
          />
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import MissedCallItem from './MissedCallItem';
import stickyBannerMixin from 'dashboard/mixins/stickyBannerMixin';
import { mixin as clickaway } from 'vue-clickaway';
import callMixin from 'dashboard/mixins/call';
import SpinnerHoory from 'shared/components/SpinnerHoory.vue';

export default {
  components: { MissedCallItem, SpinnerHoory },
  mixins: [clickaway, stickyBannerMixin, callMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  async created() {
    if (!this.missedCalls.length) {
      this.isLoading = true;
    }
    await this.$store.dispatch('call/fetchMissedCalls');
    this.isLoading = false;
  },
  methods: {
    closeMissedCallsPanel() {
      this.setMissedCallsVisibility(false);
    },
    beforeLeave(el) {
      el.style.height = `${el.offsetHeight}px`;
    },
    leave(el, done) {
      const reflow = el.offsetHeight; // Forces reflow
      el.style.transition = 'height 0.5s ease, opacity 0.5s ease';
      el.style.height = 0;
      el.style.opacity = 0;
      setTimeout(done, 500);
      return reflow;
    },
  },
};
</script>
<style lang="scss" scoped>
.missed-calls-panel-body {
  flex-direction: column;
  max-height: 90vh;
  height: fit-content;
  width: 40rem;
  background-color: var(--white);
  border-radius: var(--border-radius-medium);
  position: absolute;
  right: var(--space-small);
  top: var(--space-jumbo);
  z-index: var(--z-index-twenty);
  border-radius: var(--border-radius-large);
  background-color: var(--s-50);
  overflow-y: auto;
  min-height: 72px;
  .spinner-cover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.slide-left-right-enter-active,
.slide-left-right-leave-active {
  transition: all 0.5s ease;
}

.slide-left-right-enter {
  transform: translateX(-100%);
  opacity: 0;
  height: auto;
}

.slide-left-right-leave-to {
  transform: translateX(-100%);
  opacity: 0;
  height: 0;
}
</style>
