<template>
  <div class="sticky-bar-container">
    <div>
      <div v-if="activeRecord" class="sticky-bar-container-actions">
        <div
          v-if="!goToConversation"
          class="sticky-bar-user"
          @mouseover="
            !isPlayingRecordConversationActive
              ? (goToConversation = true)
              : null
          "
        >
          <div class="sticky-bar-avatar">
            <VFlag
              v-if="activeRecord.sender_flag"
              :flag="activeRecord.sender_flag"
              class="phone-number-flag margin-right-2"
            />
            <img
              v-if="activeRecord.sender_avatar_url"
              :src="activeRecord.sender_avatar_url"
              class="sticky-bar-avatar-img"
            />
            <Avatar
              v-else
              :username="activeRecord.sender_name"
              class="sticky-bar-sender-name"
            />
            <div class="sticky-bar-name-number">
              <div class="fw-medium fs-normal">
                {{ activeRecord.sender_number }}
              </div>
              <div class="fw-medium fs-normal color-primary text-ellipsis ">
                {{ formatCallerName(activeRecord.sender_name) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="goToConversation"
          @mouseleave="
            !isPlayingRecordConversationActive
              ? (goToConversation = false)
              : null
          "
          @click="goToActiveConversation(), (goToConversation = false)"
        >
          <div class="go-to-conversation">
            Go To Conversation
          </div>
          <div class="go-to-conversation-icon">
            <fluent-icon
              icon="go-to"
              size="12"
              view-box="0 0 12 12"
              fill-color="#fff"
            />
          </div>
        </div>
        <WaveAudio
          :audio-record="activeRecord"
          autoplay
          controls
          playback-rate
          :has-next="hasNextRecord"
          :has-previous="hasPreviousRecord"
          @seek="seekHandler"
          @rate="rateHandler"
          @next="nextHandler"
          @previous="previousHandler"
          @close="closeHandler"
        />
      </div>
    </div>
    <div v-if="missedCallsCount" class="sticky-bar-container-calls">
      <div
        v-if="!missedCallsVisibility"
        class="missed-calls-body"
        @click="setMissedCallsVisibility(true)"
      >
        <fluent-icon
          icon="missed-call"
          size="18"
          view-box="0 0 18 18"
          fill-color="#FB7A89"
        />
        <div class="margin-left-2 fw-medium">
          {{ missedCallsCount }} {{ $t('CONVERSATION.MISSED_CALLS') }}
        </div>
      </div>
      <div v-if="missedCallsVisibility" class="missed-calls-body-actions">
        <div
          class="missed-calls-body missed-calls-body-clear"
          @click.stop="clearMissedCalls()"
        >
          <span class="fw-medium">{{
            $t('CONVERSATION.HEADER.CLEAR_ALL')
          }}</span>
        </div>
        <div
          class="missed-calls-body missed-calls-body-close"
          @click="setMissedCallsVisibility(false)"
        >
          <fluent-icon icon="dismiss" size="16" fill-color="#404040" />
        </div>
      </div>

      <MissedCallPanel v-if="missedCallsVisibility" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Avatar from 'dashboard/components/widgets/Avatar';
import WaveAudio from './WaveAudio';
import MissedCallPanel from './MissedCallPanel';
import stickyBannerMixin from 'dashboard/mixins/stickyBannerMixin';
import VFlag from 'dashboard/components/ui/VFlag';
import callMixin from 'dashboard/mixins/call';

export default {
  components: {
    Avatar,
    WaveAudio,
    VFlag,
    MissedCallPanel,
  },
  mixins: [stickyBannerMixin, callMixin],
  data() {
    return {
      goToConversation: false,
      panelShown: false,
      showMissedCalls: true,
    };
  },
  computed: {
    ...mapGetters({
      audioRecords: 'stickyBanner/getAudioRecords',
      bannerVisibility: 'stickyBanner/getBannerVisibility',
    }),
  },

  methods: {
    closeHandler() {
      this.setBannerVisibility(false);
      this.setActiveRecord(null);
      this.setPlayPause('stop');
      this.setRateData(1);
    },
    clearMissedCalls() {
      this.$store.dispatch('call/readMissedCall', {
        primaryActorId: 'all',
      });
      this.$store.dispatch('setConversationsCallStatus', {
        status: null,
        conversationId: 'all',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-bar-container {
  border: 1px solid var(--s-75);
  padding: var(--space-small) var(--space-smaller);
  background-color: var(--white);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--shadow-medium);
  height: 100%;
  max-height: 72px;
  display: flex;
  align-items: center;
  margin: 0 var(--space-small) 0 var(--space-smaller);
  position: absolute;
  width: calc(100% - var(--space-smaller));
  top: 0;
  left: 0;
  justify-content: space-between;
  .sticky-bar-container-actions {
    border-radius: var(--border-radius-large);
    padding: var(--space-smaller);
    min-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sticky-bar-user {
    border-radius: var(--border-radius-medium);
    background-color: var(--s-75);
    padding: var(--space-small);
    height: 56px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .sticky-bar-avatar {
      display: flex;
      align-items: center;
      position: relative;
      .sticky-bar-avatar-img {
        width: 40px;
        height: 40px;
        border-radius: var(--border-radius-rounded);
      }
      .sticky-bar-name-number {
        margin-left: var(--space-slab);
        width: 135px;
        @media (max-width: 1290px) {
          display: none;
        }
      }
      .sticky-bar-sender-name {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
    }
    @media (max-width: 1290px) {
      border-radius: var(--border-radius-semirounded);
    }
  }
  .go-to-conversation {
    background-color: var(--v-400);
    color: var(--white);
    font-size: var(--font-size-normal);
    border-radius: var(--border-radius-medium);
    padding: var(--space-small) var(--space-normal) var(--space-small)
      var(--space-small);
    height: 56px;
    width: 203px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width: 1290px) {
      display: none;
    }
  }
  .go-to-conversation-icon {
    display: none;
    width: 56px;
    height: 56px;
    background-color: var(--v-400);
    border-radius: var(--border-radius-rounded);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width: 1290px) {
      display: flex;
    }
  }
  .missed-calls-body {
    background-color: #fb7a891a;
    border-radius: var(--border-radius-semirounded);
    padding: var(--space-small) var(--space-slab);
    color: #fb7a89;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: var(--font-size-normal);
    margin-right: var(--space-small);
    cursor: pointer;
  }
  .missed-calls-body-close {
    color: #404040;
    background-color: #f7f7f7;
    padding: var(--space-one);
  }
  .missed-calls-body-clear {
    color: #404040;
    background-color: #f7f7f7;
    padding: var(--space-small) var(--space-two);
  }
  .missed-call-close {
    margin-right: var(--space-small);
  }
  .phone-number-flag {
    height: 18px;
    width: 18px;
    overflow: hidden;
    border-radius: var(--border-radius-rounded);
    position: absolute;
    left: 22px;
    top: 2px;
  }
  .missed-calls-body-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
